<template>
<ServerList ref="ServerList" />
<Dock />
</template>

<script>
import Dock from '@/components/Dock'
import ServerList from '@/components/ServerList'
export default {
  name: 'App',
  components: {
    Dock,
    ServerList
  },
  data() {
    return {
      appdown: this.$store.state.down,
    };
  },
  watch:{
  
  },
  methods:{
    clickBtn1: function(){
      this.$store.commit('cheBtn1',  !this.$store.state.btn1);
    },
    clickBtn2: function(){
      this.$store.commit('cheBtn2',  !this.$store.state.btn2);
    },
    clickBtn3: function(){
      this.$store.commit('cheBtn3',  !this.$store.state.btn3);
    },
    // clickUp: function(){
    //   this.$store.commit('cheUp');
    // },
    // clickDown: function(){
    //   this.$store.commit('cheDown');
    // },
    // clickReset: function(){
    //   this.$store.commit('resetUpDown');
    // }
    
    
  }
}
</script>

<style lang="scss">
@import "/src/assets/mnb-app.scss", "/src/assets/mnb-app-style.scss";
body{
  background-color: $dp0;
  @include margins;

}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 // text-align: center;
  color: $fg0;
  a{
    color:$green2;
    &:hover{
      color: $pink2;
    }
  }
}
</style>
