<template>
  <div  class="listRow" v-if="this.$store.state.offline == 1 || data.status == 'DOWN'">  
    <!--:key="rpid" <div class="col"> -->
      <div class="status" :class="data.status"></div>
    <!-- </div> -->
    <div class="col">
      <div class="name">{{data.name}}</div>
      <div class="ip">{{data.ip}}</div>  
    </div>
  </div>
</template>

<script>
export default {
  name: "ListRaw",
  created() {},
  mounted(){

},
  data() {
    return {
    // rpid:0,
     //show: true 
    };
  },
  watch: {
    
  },
  computed: {
   // rpid: function () { return Date.now(); },
   

  },
  props: {
    data:'',
    hidden: String
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
@import "/src/assets/mnb-app.scss";
@mixin bg_param{
    background-size: 24px 24px;
    background-position: 4px 4px;
    background-repeat: no-repeat;
} 
.listRow {
  display: flex;
  justify-content: left;
  align-items: center;


 .status{
   display: block;
   width:32px;
   height: 32px;
   border-radius: 16px;
   margin-right:16px;
   float:left;
   @include bg_param();
   &.UP{
     background-color: $mygreenrow;
     //background-image: url(../assets/check.svg);
     background-image: url(/img/check.png?v=2);
    
   }
   &.DOWN{
     background-color: $myredrow;
     //background-image: url(../assets/exclamation.svg);
     background-image: url(/img/fail.png?v=2);
   }
 }
.name{
  font-weight:500;
}
 .ip{
   font-size: .8em;
   color:$fg4;
 }

}
</style>