<template>
  <div :class="addclass">
    {{text}}
  </div>
</template>

<script>
export default {
  name: "ListHead",
  created() {},
  data() {
    return {};
  },
  props: {
    text:'',
    addclass:'' 
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.date{
  font-size: .87em;
}
</style>