<template>
  <div class="serverList">
    <!-- <el-button type="primary" @click="listShow">S</el-button>
    <el-button type="primary" @click="listHide">D</el-button> -->
    <!-- <button @click="this.$store.state.offline = !this.$store.state.offline">Hide</button> -->
    <!-- <button @click="refresh" :disabled="this.$store.state.btn3">Refresh {{this.$store.state.rand}}</button> -->
    <ListHead class="HeadDate"  :text="list.date" addclass="date" />
    <Service :sup="parseInt(list.up,10)" :sdown="parseInt(list.down,10)" :key="this.$store.state.rand" />
    <el-row :gutter="8" class="list" v-for="(val, name) in list" :key="name">
      <el-col :span="24">
        <ListHead class="ListHead" v-if="name != 'date' && name != 'up' && name != 'down'" :text="name" addclass="listHead" />
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6"  v-for="(val2, name2) in val" :key="name2">
        <div  v-if="name != 'date' && name != 'up' && name != 'down'">
          <ListRow class="ListRow" :data="val2"  />
        </div>
      </el-col>
    </el-row>  
  </div>
</template>

<script>
import ListRow from '@/components/ListRow'
import ListHead from '@/components/ListHead'
import Service from '@/components/Service'
//
import push from 'push.js'
//
export default {
  name: "ServerList",
  created() {},
  data() {
    return {
      list:0,
      pid:0,
      basetytle:'Астэйс Монитор: ',
      lmpColor:'green',
    };
  },
  components:{
    ListRow,
    ListHead,
    Service
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    this.refresh();
    this.autorefresh();
  },
   destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  props: {},
  computed: {
    pid: function () { return Date.now(); },
  },
  methods: {
    handleScroll (event) {
      // Any code to be executed when the window is scrolled
      //this.isUserScrolling = (window.scrollY > 0);
      if(window.scrollY > 24){
        this.$store.commit('cheBtn1',  false);
      }else{
        this.$store.commit('cheBtn1',  true);
      }
      
    },
    refresh: function () {
      // Simple GET request using fetch
      //this.disabled = true;
      
      this.$store.commit('offBtn');
      fetch("https://www2.asteis.net/servmon.php?key=dfghjkljFDSG432FSGi886586SD126S4e")
        .then((response) => response.json())
        .then((data) => (this.list = data));
      //this.errnum += 1;
      
      if(this.$store.state.rand > 100)
        {
          this.$store.state.rand = 1;
        } else{
          this.$store.state.rand +=1;
        }
      setTimeout(()=>{
        this.$store.commit('cheBtn3', false);
        
        if(this.$store.state.down > 0){this.$store.state.btn2 = false;}
        this.setTitle();
        //this.disabled = false;
        //document.getElementById("listup").click();
      },3000)
    },
    autorefresh: function (){
      setInterval(()=>{       
        this.$store.state.prevdown = this.$store.state.down; 
        this.refresh();
        this.notifyMe();
      },60000)
    },
    setTitle: function() {
      let addtext;
      if (this.$store.state.down > 0) {
        addtext="Офлайн " + this.$store.state.down;
        //this.lmpColor = "red";
      }else{
        addtext="Oнлайн: " + this.$store.state.up;
        //this.lmpColor = "green";
      }
      document.title=this.basetytle + addtext;
    },
    notifyMe:function(){
      //push.create("notify - " + this.$store.state.down + "-" + this.$store.state.prevdown + "+" + parseInt(this.$store.state.down,10) );
      setTimeout(() =>{
            let notydown = parseInt(this.$store.state.down,10);
            let notyprev = parseInt(this.$store.state.prevdown,10);

            //push.create("notify + " + notydown + " + " + notyprev);

            if(notydown > 0 && notydown > notyprev ){
              push.create("Важное оборудование недоступно", {body: "Устройств офлайн: " + notydown + ".", icon: '/img/fail.png', timeout: 60000, onClick: function () { window.focus(); this.close();} });
            }else if(notyprev > 0 && notydown == 0){
              push.create("Важное оборудование снова доступно", {body: "Убедитесь, что все работает правильно.", icon: '/img/check.png', timeout: 60000, onClick: function () { window.focus(); this.close();} });
            }
        },5000);
    },
    listShow:function(){
      document.getElementsByClassName("serverList")[0].style.opacity = "100%";
    },
    listHide:function(){
      document.getElementsByClassName("serverList")[0].style.opacity = "0%";
    },
  },
  mounted() {
    
    
  },
};
</script>

<style lang="scss" scoped>
@import "/src/assets/mnb-app.scss";
.serverList{
transition: all 0.125s, color 0.5s, font-size 0.5s;
  .ListHead,.HeadDate{
      @include header_align;
    }

  .list{
    //background-color: $dp1;
    color: $fg1;
    .ListHead {
      //background-color: $dp2;
      color: $fg2;
      font-weight: 600;
      margin:8px 0;

      
    }
       
    .ListRow {
      background-color: $dp6;
      color:$fg6;
      margin-bottom: 8px;
      border-radius: 8px;
      padding: 12px;
      text-align: left;
      &:hover{
        background-color: $dp8;
        color:$fg8;
      }
    }
  }
  

}
</style>