<template>
  <div id="dock">
    <DockButton @click="goHome" :param="btn1" :disabled="this.$store.state.btn1" />
    <DockButton @click="this.$store.state.offline = !this.$store.state.offline" :param="btn2" :disabled="this.$store.state.btn2" />
    <DockButton @click="refresh" :param="btn3" :disabled="this.$store.state.btn3" />

  </div>
</template>

<script>
import DockButton from "@/components/DockButton";
export default {
  name: "Dock",
  components: {
    DockButton,
  },
  data() {
    return {
      btn1: {
        text: "Домой",
        icon: "img/dock/home.svg?fill=red",
        name: "btn1",
      },
      btn2: {
        text: "Офлайн",
        text2: "Все",
        icon: "img/dock/unlink.svg",
        icon2: "img/dock/link.svg",
        name: "btn2",
      },
      btn3: {
        text: "Обновить",
        icon: "img/dock/refresh.svg",
        name: "btn3",
      },
    };
  },

  props: {},
  computed() {

  },
  methods: {
    goHome(){
      //this.$parent.refresh();
      //this.$parent.$refs.ServerList.remove();
      this.$parent.$refs.ServerList.listHide();
      setTimeout(()=>{
      
      window.scrollTo({
        top: 0,
        left: 0,
        //behavior: "smooth",
      });
      this.$parent.$refs.ServerList.listShow();
      },250);
      
      //this.$parent.$refs.ServerList.show();

    if(this.$store.state.btn3 == false){this.$parent.$refs.ServerList.refresh();}

    },
    refresh: function() {
      this.$store.state.offline = 1;
      this.$parent.$refs.ServerList.refresh();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "/src/assets/mnb-app.scss";

#dock{
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  align-items: stretch;
  //height:$p_dock_height;
  background-color: $dp12;
  border-top:2px solid $dp24;
  color: $fg12;
  padding: 8px 12px 12px 12px;
  box-shadow: 0 -4px 8px rgba(0,0,0,.25);
  button{
    margin: 0 12px;
    background: none;
    //border: 1px solid red;
    min-width: $icon_min_width;
    max-width: $icon_max_width;
    color: $fg12;
    transition: all 0.5s, color 0.5s;

    @include no_select;
    filter: opacity($inactive_icon_opacity);

    &:active{
      filter: opacity($active_icon_opacity);
    }
    &:disabled{
     filter: opacity($disabled_icon_opacity);
    }
  }
}
</style>