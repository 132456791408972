<template>
  <div>
    <!-- {{sup}}|{{sdown}}|{{this.$store.state.up}}|{{this.$store.state.down}} -->
  </div>
</template>

<script>
export default {
  name: "Servicen",
  mounted() {
    setTimeout(()=>{
      this.$store.state.down = this.sdown;
      this.$store.state.up = this.sup;
      //alert(this.$store.state.up);
    },500);
    
  },
  data() {
    return {};
  },
  props: {
    sup: {type: Number, default: 0},
    sdown: {type: Number, default: 0}
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped></style>