import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
//import './assets/mnb-app.scss'
//import './assets/mnb-app-style.scss'
import ElementPlus from 'element-plus'
//import 'element-plus/theme-chalk/index.css'
import 'element-plus/theme-chalk/display.css'
import 'element-plus/theme-chalk/el-col.css'
import 'element-plus/theme-chalk/el-row.css'


createApp(App).use(ElementPlus).use(store).mount('#app')
