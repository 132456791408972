<template>
  <button class="dockBtn" :id="param.name" :name="param.name">
    <div class="btnIcon">
      <img v-if="param.icon2 && this.$store.state.offline==0" :src="param.icon2">
      <img v-else :src="param.icon">
      <div class="bohavior" v-if="param.name=='btn2' && this.$store.state.down > 0">{{this.$store.state.down}}</div>
    </div>
    <div class="btnText">
      <span v-if="param.text2 && this.$store.state.offline==0">{{param.text2}}</span>
      <span v-else>{{param.text}}</span>
      
    </div>
    
  </button>
</template>

<script>
export default {
  name: "DockButton",
  created() {},
  data() {
    return {};
  },
  props: {
    param:''
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "/src/assets/mnb-app.scss";

@keyframes loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .dockBtn{
    border: none;
    font-size: $text_label_font_size;
    .btnIcon{
      img {
        height: $icon_height;
      }
    }
    .btnText{
      height: $text_label_font_size;
      line-height: $text_label_font_size;
      //text-transform: uppercase;
    }
    .bohavior{
      display: block;
      position: absolute;
      top: 1%;
      left: 70%;
      padding: 1px 2px;
      min-width: 14px;
      border-radius: 7px;
      background-color: $myreddock;
      color: $white
    }
  }
  #btn3:disabled{
      .btnIcon{
        img{
        animation-duration: 3s;
        animation-name: loading-spinner;
        animation-iteration-count: infinite;
        }
      }
    }
</style>