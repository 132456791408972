import { createStore } from 'vuex'

export default createStore({
  state: {
    btn1: true,
    btn2: true,
    btn3: true,
    up: 0,
    down: 0,
    prevdown: 0,
    rand: 0,
    offline: 1

  },
  mutations: {
    cheBtn1: function(state,val) {
      state.btn1 = val;
    },
    cheBtn2: function(state,val) {
      state.btn2 = val;  
    },
    cheBtn3: function(state,val) {
      state.btn3 = val;  
    },
    cheUp: function(state){
      state.up += 1;
    },
    cheDown: function(state){
      state.down += 1;
      state.btn2 = false; 
    },
    resetUpDown: function(state){
      state.down=0;
      state.up=0;
      state.btn2 = true; 
    },
    offBtn: function(state){
      state.btn1 = true;
      state.btn2 = true; 
      state.btn3 = true; 
    }
    
  },
  actions: {
  
  },
  modules: {
  }
})
